import React from 'react'
//import aboutImg1 from '../../assets/images/about/about-img1.png'
import StarIcon from '../../assets/images/star-icon.png'
import dolibarr from '../../assets/images/home/dolibarr.png'

const ContentERP = () => {
    return (
        <section className="overview-area pt-70 pb-30 bg-ffe3d4">
            <div className="container">
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content h2-top">
                            <h2>L’ERP, gestion de vos processus</h2>
                            <p><span className='bold'>L’ERP (Progiciel de Gestion Intégré)</span> est un planificateur qui vous permet d’automatiser votre gestion et d’assurer l’efficacité de vos processus opérationnels en centralisant l’ensemble de vos données sur un seul outil.</p>

                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        Fonctionnalités principales :
                                    </span>
                                </div>
                            </div>
                            <div>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Facturation et comptabilité</span></li>
                                <li><span><i className='bx bx-check'></i> Ressources humaines</span></li>
                                <li><span><i className='bx bx-check'></i> Planification de la production</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion des stocks</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion commerciale</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion de projet</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion documentaire</span></li>
                                <li><span><i className='bx bx-check'></i> E-commerce</span></li>
                            </ul>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={dolibarr} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content h2-top">
                            
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        Les avantages de l'ERP sont :
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentERP
import React from 'react'
//import icon1 from '../../assets/images/icons/icon1.png'
import automatisation from '../../assets/images/icones/automatisation.gif'
import optimisation from '../../assets/images/icones/optimisation.gif'
import centralisation from '../../assets/images/icones/centralisation.gif'
import shape3 from '../../assets/images/services/service-shape3.png'

const AvantagesCRM = () => {
    return (
        <div className="featured-services-area pt-50 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={automatisation} alt="features" />
                            </div>
                            <h3 className='ptb-15'>
                                Automatiser le cycle de vente
                            </h3>
                            <p>de la prospection à la fidélisation client</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={centralisation} alt="features" />
                            </div>

                            <h3 className='ptb-15'>
                                Centraliser les données
                            </h3>

                            <p>concernant vos contacts, clients et prospects</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={optimisation} alt="features" />
                            </div>

                            <h3>
                                Optimiser vos actions marketing
                            </h3>

                            <p>afin de mieux cibler vos offres commerciales</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-70">
                    <p>Nous mettons à votre disposition un large panel de fonctionnalités standards et des modules complémentaires depuis une interface unique. <span className='bold'>L’outil s’adapte à vous et à votre organisation</span>.</p>
                    <p>Dolibarr est un logiciel soutenu par des milliers d’utilisateurs et de développeurs ce qui en fait <span className='bold'>un des logiciels les plus fiables et innovants sur le marché</span>. Un logiciel accessible, performant et conforme aux réglementations. <span className='bold'>Des mises à niveau et des nouvelles fonctionnalités</span> sont régulièrement intégrées pour vous offrir une expérience utilisateur optimale.</p>
                    <p className="pt-50">* Consultez le <a href="https://www.dolibarr.org/" target="_blank" rel="noreferrer" className='bold'>site Web du logiciel Dolibarr</a> pour plus d'informations sur le logiciel Dolibarr et ses fonctionnalités.</p>
                </div>
            </div>
        </div>
    )
}

export default AvantagesCRM;
import React from 'react';
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'
//import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png'
import OurMissionImg from '../../assets/images/our-mission/our-mission1.png'
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const ModDolibarr = () => {
    return (
        <section className="our-mission-area ptb-70">
            <div className="container-fluid">
                <div className="row align-items-center">
                    
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={OurMissionImg} alt="" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={StarIcon} alt="" /> 
                                    Dolibarr votre outil de gestion
                                </span>
                                <h2>ERP/CRM : Développez votre entreprise avec Dolibarr</h2>
                                <p><span className='bold color-seekreet'>Dolibarr</span> est un logiciel Open Source (c’est-à-dire libre de gestion) pour gérer toute votre entreprise et qui intègre à la fois un ERP et un CRM.<br></br>
                                Une solution développée pour <span className='bold'>optimiser le développement et la croissance de votre activité pour en réduire les coûts</span>.<br></br>
                                Une solution simple d’utilisation complète et modulaire qui permet d’automatiser les tâches chronophages pour gagner en performance quelle que soit la taille de votre organisation.<br></br><br></br>
                                Vous pouvez <span className='bold'>personnaliser le logiciel</span>, à tout moment, en activant uniquement les fonctionnalités dont vous avez besoin</p>

                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> Demander une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ModDolibarr;
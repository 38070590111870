import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const ContentTwo = () => {
    return (
        <section className="how-its-work-area pt-30 pb-70 bg-e3fbff">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Un accompagnement personnalisé
                            </span>
                            <p>Notre équipe vous accompagne <span className='bold'>de la conception à la concrétisation de votre projet</span>. De l’audit de vos besoins à la formation des utilisateurs, au suivi de vos attentes, nous sommes présents à chaque étape de l’intégration de l’outil.</p>
                            <p>Pour simplifier la réussite de votre projet nous installons, configurons et personnalisons <span className='color-seekreet'>Dolibarr</span> selon vos préférences.</p>
                            <div className="h2-top">
                                <span className="sub-title h2-top">
                                    <img src={starIcon} alt="banner" /> 
                                        Notre expertise métier
                                </span>
                            </div>
                            <p>Spécialisée dans le développement de solutions logicielles, <span className='color-seekreet bold'>Seekreet</span> est à votre disposition pour vous conseiller et répondre à vos questions. Notre objectif est de vous aider à exploiter au mieux <span className='color-seekreet'>Dolibarr</span>.</p>
                            <p>Vous bénéficiez de l’expertise de nos collaborateurs vous garantissant une  <span className='bold'>assistance de proximité</span> et un support technique proactif.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentTwo
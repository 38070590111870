import React from 'react'
//import aboutImg2 from '../../assets/images/about/about-img2.png'
import crm from '../../assets/images/crm.png'
import StarIcon from '../../assets/images/star-icon.png'

const ContentCRM = () => {
    return (
        <section className="overview-area pt-70 pb-30">
            <div className="container">
                <div className="overview-box">

                    <div className="overview-image">
                        <div className="image">
                            <img src={crm} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content h2-top">
                            <h2>LE CRM, relation client et prospection</h2>
                            <p>Le <span className='bold'>CRM (Customer Relationship Management)</span> est un <span className='bold'>gestionnaire de la relation client</span> (GRC), qui permet de prospecter, de fidéliser et surtout de dynamiser les ventes.</p>

                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        Fonctionnalités principales :
                                    </span>
                                </div>
                            </div>
                            <div>
                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Gestion des contacts, clients, prospects et fournisseurs</span></li>
                                <li><span><i className='bx bx-check'></i> Historique des échanges</span></li>
                                <li><span><i className='bx bx-check'></i> Analyse des performances commerciales</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion des prises de rendez-vous</span></li>
                                <li><span><i className='bx bx-check'></i> Gestion de projets</span></li>
                                <li><span><i className='bx bx-check'></i> Gestions des opportunités</span></li>
                                <li><span><i className='bx bx-check'></i> Marketing</span></li>
                            </ul>
                            </div>
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content h2-top">
                            
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={StarIcon} alt="" /> 
                                        Les avantages d'un CRM sont :
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentCRM
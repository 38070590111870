import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModDolibarr from "../components/Dolibarr/ModDolibarr"
import ContentERP from "../components/Dolibarr/ContentERP"
import AvantagesERP from "../components/Dolibarr/AvantagesERP"
import ContentCRM from "../components/Dolibarr/ContentCRM"
import AvantagesCRM from "../components/Dolibarr/AvantagesCRM"
import Content from "../components/Dolibarr/Content"
import ContentTwo from "../components/Dolibarr/ContentTwo"
import NosOffres from "../components/Dolibarr/NosOffres"
import { Helmet } from "react-helmet"


const Dolibarr = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | ERP CRM dolibarr</title>
        <meta name="description" content="Seekreet, une équipe spécialisée dans le data management. Vous cherchez un CRM, ERP, nous paramétrons DOLIBARR selon vos besoins. Paramétrage, hébergement, assistance nous vous..." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Dolibarr" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Service Informatique" 
          parentPageUrl="/service-informatique"
          activePageText="Dolibarr" 
      />
      <ModDolibarr />
      <ContentERP />
      <AvantagesERP />
      <ContentCRM />
      <AvantagesCRM />
      <Content />
      <ContentTwo />
      <NosOffres />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Dolibarr
import React from 'react'
//import {Link} from 'gatsby'
import gestionGlobale from '../../assets/images/icones/gestion-globale.gif'
import reseauCloud from '../../assets/images/icones/reseau-cloud.gif'
import decisions from '../../assets/images/icones/decisions.gif'
import shape3 from '../../assets/images/services/service-shape3.png'

const AvantagesERP = () => {
    return (
        <div className="featured-services-area pt-50 pb-70 bg-ffe3d4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={gestionGlobale} alt="features" />
                            </div>
                            <h3>
                                Améliorer la gestion globale de l’entreprise
                            </h3>
                            <p className='pb-30'>en optimisant les processus internes</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={reseauCloud} alt="features" />
                            </div>

                            <h3 className='pb-30'>
                                Centraliser les données
                            </h3>

                            <p>pour améliorer l’accès aux informations et fluidifier la communication entre les services</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-item">
                            <div className="icon">
                                <img src={decisions} alt="features" />
                            </div>

                            <h3>
                                Faciliter les prises de décisions stratégiques
                            </h3>

                            <p>grâce au tableau de bord qui vous permet d’avoir une vue globale de votre activité</p>

                            <div className="shape">
                                <img src={shape3} alt="features" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvantagesERP